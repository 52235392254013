











































































































































































































import { FormBlock, SvgImage } from '@/app/components';
import { useAxios, useFeatureFlags, useFilters } from '@/app/composable';
import store from '@/app/store';
import { RunnerAPI } from '@/modules/data-checkin/api';
import RunnerTokens from '@/modules/user/views/RunnerTokens.vue';
import {
    CalendarIcon,
    ClipboardCopyIcon,
    CloudUploadIcon,
    PlusIcon,
    ShareIcon,
    ViewGridIcon,
} from '@vue-hero-icons/outline';
import { defineComponent, reactive, ref } from '@vue/composition-api';
import dayjs from 'dayjs';

export default defineComponent({
    name: 'FederatedDataSpace',
    components: {
        PlusIcon,
        SvgImage,
        CalendarIcon,
        ShareIcon,
        CloudUploadIcon,
        FormBlock,
        RunnerTokens,
        ClipboardCopyIcon,
        ViewGridIcon,
    },
    props: {
        runners: {
            type: Array,
            default: () => [],
        },
        binaries: {
            type: Object,
            default: () => {},
        },
    },
    setup(props, { root, emit }) {
        const BACKEND_URL = process.env.VUE_APP_BACKEND_URL;
        const { exec, loading, error } = useAxios(true);
        const { formatDateTime } = useFilters();
        const { isEnabled: isFeatureEnabled } = useFeatureFlags();
        const isAnalyticsEnabled = isFeatureEnabled('analytics');
        const user = ref(store.state.auth.user);

        const registerRunner = ref(false);
        const registeredRunner = ref(false);
        const runnerRegistration: any = reactive({
            name: null,
            token: '',
        });

        const tokenToCopy = ref<any>(null);
        const hostToCopy = ref<any>(null);

        const showRunnerRegistration = () => {
            registerRunner.value = true;
            emit('scroll-to-top');
        };

        const generateRunnerToken = () => {
            const payload = {
                name: runnerRegistration.name,
            };

            exec(RunnerAPI.generateToken(payload))
                .then((res: any) => {
                    runnerRegistration.token = res.data.token;
                    registeredRunner.value = true;
                    registerRunner.value = false;
                    runnerRegistration.name = null;
                })
                .catch(() => {
                    (root as any).$toastr.e('The token could not be generated due to an error.', 'Error');
                });
        };

        const cancelRunnerRegistration = () => {
            registerRunner.value = false;
            runnerRegistration.name = null;
        };

        const copyToClipboard = () => {
            tokenToCopy.value = document.querySelector('#token');
            if (tokenToCopy.value.value) {
                tokenToCopy.value.setAttribute('type', 'text');
                tokenToCopy.value.select();
                document.execCommand('copy');
                tokenToCopy.value.setAttribute('type', 'hidden');
            }
        };

        const copyHostToClipboard = () => {
            hostToCopy.value = document.querySelector('#host');
            if (hostToCopy.value.value) {
                hostToCopy.value.setAttribute('type', 'text');
                hostToCopy.value.select();
                document.execCommand('copy');
                hostToCopy.value.setAttribute('type', 'hidden');
            }
        };

        return {
            loading,
            error,
            user,
            formatDateTime,
            dayjs,
            cancelRunnerRegistration,
            registerRunner,
            registeredRunner,
            generateRunnerToken,
            showRunnerRegistration,
            runnerRegistration,
            BACKEND_URL,
            copyHostToClipboard,
            copyToClipboard,
            isAnalyticsEnabled,
        };
    },
});
