































































































import { Scrollbar, Tabs } from '@/app/components';
import { useAxios, useFeatureFlags } from '@/app/composable';
import store from '@/app/store';
import { computed, defineComponent, ref } from '@vue/composition-api';
import { OrbitSpinner } from 'epic-spinners';
import * as R from 'ramda';
import dayjs from 'dayjs';
import { CalendarIcon, ShareIcon, CloudUploadIcon, ViewGridIcon } from '@vue-hero-icons/outline';
import { RunnerAPI } from '@/modules/data-checkin/api';
import { WorkflowAPI } from '@/modules/workflow-designer/api';
import { FederatedDataSpace } from '../components';

export default defineComponent({
    name: 'DataSpaceManagement',
    metaInfo: {
        title: 'Data Space Management',
    },
    components: {
        OrbitSpinner,
        Tabs,
        Scrollbar,
        FederatedDataSpace,
        CalendarIcon,
        ShareIcon,
        CloudUploadIcon,
        ViewGridIcon,
    },
    setup(props, { root }) {
        const dataSpaceManagementRef = ref();
        const { exec, loading, error } = useAxios(true);
        const { isEnabled: isFeatureEnabled, flag } = useFeatureFlags();
        const isOnPremiseRunnerEnabled = flag('on-premise');
        const isAnalyticsEnabled = isFeatureEnabled('analytics');
        const binaries = ref<any>({ tray: [], headless: [] });
        const user = ref(store.state.auth.user);
        const organisation = computed(() => store.getters.organisation.details);
        const activeTab = ref(0);
        const tabs = ref([
            { id: 0, title: 'Centralised Cloud Data Space' },
            // { id: 1, title: 'Private Cloud' }, // TODO: inactive for 1st release
            { id: 1, title: 'Federated Data Space' },
        ]);
        const generalInfo = ref({
            'data-checkin': 0,
            analytics: 0,
            assets: 0,
        });

        const allRunners = ref([]);

        if (isOnPremiseRunnerEnabled.value)
            exec(WorkflowAPI.getRunnersWithPipelinesCount(user.value.organisationId))
                .then((res: any) => {
                    allRunners.value = res.data;
                    getBinaries();
                })
                .catch(() => {
                    (root as any).$toastr.e('An error occurred.', 'Error');
                });

        const tabClicked = (idx: number) => {
            activeTab.value = idx;
        };

        const getBinaries = () => {
            exec(RunnerAPI.getLatestBinaries()).then((res: any) => {
                binaries.value = res.data;
            });
        };

        const scrollToTop = () => {
            if (dataSpaceManagementRef.value) dataSpaceManagementRef.value.scrollToTop();
        };

        const getFinalisedPipelinesOnCloud = () => {
            exec(WorkflowAPI.getFinalisedWorkflowsOnCloud(user.value.organisationId)).then((res: any) => {
                res.data.forEach((stats: { type: string; count: string }) => {
                    generalInfo.value[stats.type] = stats.count;
                });
            });
        };

        getFinalisedPipelinesOnCloud();

        return {
            tabs,
            activeTab,
            loading,
            error,
            tabClicked,
            user,
            R,
            allRunners,
            binaries,
            generalInfo,
            getFinalisedPipelinesOnCloud,
            organisation,
            dayjs,
            isAnalyticsEnabled,
            dataSpaceManagementRef,
            scrollToTop,
        };
    },
});
